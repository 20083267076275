<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>
          Cadastro de modelo de treinamento
        </h3>
      </div>
      <div class="card-body">
        <form @submit.prevent="salvar">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group" :class="{ 'form-group--error': $v.treinamento.treinamento_tipo_id.$error }">
                <label for="treinamento_tipo_id" class="required">Tipo de evento</label>
                <select class="form-control" name="treinamento_tipo_id" id="treinamento_tipo_id" v-model="treinamento.treinamento_tipo_id">
                  <option v-for="(item, index) in tiposTreinamento" :key="index" :value="item.id">{{item.nome}}</option>
                </select>
                <div class="error-form text-danger" v-if="!$v.treinamento.treinamento_tipo_id.required">Campo obrigatório</div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-md-6 col-sm-12">
              <div class="form-group" :class="{ 'form-group--error': $v.treinamento.nome_atividade.$error }">
                <label for="nome" class="required">Atividade</label>
                <input type="text" id="nome" name="nome"
                  class="form-control" v-model="treinamento.nome_atividade">
                  <div class="error-form text-danger" v-if="!$v.treinamento.nome_atividade.required">Campo obrigatório</div>
              </div>
            </div> -->
            <div class="col-md-6 col-sm-12">
              <div class="form-group" >
                <label for="norma_id" class="required">Norma</label>
                <select @change="buscarProcessos" class="form-control" name="norma_id" id="norma_id" v-model="treinamento.norma_id">
                  <option v-for="(item, index) in normas" :key="index" :value="item.id">{{item.nome}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group" >
                <label for="processo_id" class="required">Processo</label>
                <select class="form-control" name="processo_id" id="processo_id" v-model="treinamento.processo_id">
                  <option v-for="(item, index) in processos" :key="index" :value="item.id">{{item.nome}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group" :class="{ 'form-group--error': $v.treinamento.objetivo.$error }">
                <label for="objetivo" class="required">Objetivos</label>
                <span class="float-right" :class="{'text-danger' : treinamento.objetivo.length > 800}"><small>{{objetivoCaracteres()}}</small></span>
                <textarea class="form-control" @keyup="objetivoCaracteres" name="objetivo" id="objetivo" rows="4" v-model="treinamento.objetivo"></textarea>
                <div class="error-form text-danger" v-if="!$v.treinamento.objetivo.required">Campo obrigatório</div>
              </div>
            </div>
          
            <div class="col-md-2 col-sm-12">
              <div class="form-group" :class="{ 'form-group--error': $v.treinamento.carga_horaria_pratica.$error }">
                <label for="carga_horaria" class="required">Carga horária prática</label>
                <input type="text"
                  class="form-control" name="carga_horaria_pratica" id="carga_horaria_pratica" v-mask="'##:##'" v-model="treinamento.carga_horaria_pratica" placeholder="00:00">
                  <div class="error-form text-danger" v-if="!$v.treinamento.carga_horaria_pratica.required">Campo obrigatório</div>
              </div>
            </div>
            <div class="col-md-2 col-sm-12">
              <div class="form-group" :class="{ 'form-group--error': $v.treinamento.carga_horaria_teorica.$error }">
                <label for="carga_horaria" class="required">Carga horária teórica</label>
                <input type="text"
                  class="form-control" name="carga_horaria_teorica" id="carga_horaria_teorica" v-mask="'##:##'" v-model="treinamento.carga_horaria_teorica" placeholder="00:00">
                  <div class="error-form text-danger" v-if="!$v.treinamento.carga_horaria_teorica.required">Campo obrigatório</div>
              </div>
            </div>
            <div class="col-md-2 col-sm-12">
              <div class="form-group">
                <label for="carga_horaria" >Reciclar em</label>
                <div class="input-group">
                  <input v-model="treinamento.dias_reciclagem" type="number" class="form-control">
                  <div class="input-group-append">
                    <span class="input-group-text">dias</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group" :class="{ 'form-group--error': $v.treinamento.conteudo_programatico.$error }">
                <label for="carga_horaria" class="required">Conteúdo programático</label>
                <ckeditor v-model="treinamento.conteudo_programatico" :editor="editor" :config="editorConfig" ></ckeditor>
                <div class="error-form text-danger" v-if="!$v.treinamento.conteudo_programatico.required">Campo obrigatório</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-5">
              <button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
              <button type="submit" class="btn btn-primary float-right">SALVAR</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import treinamentoModelosRepository from './../../../services/api/treinamentoModelosRepository'
import treinamentoTiposRepository from './../../../services/api/treinamentoTiposRepository'
import normasRepository from './../../../services/api/normasRepository'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
export default {
  name: 'TreinamentoModeloCadastro',
  components: {
    ckeditor: CKEditor.component
  },
  validations: {
    treinamento: {
      objetivo: {
        required, maxLength: maxLength(1000)
      },
      treinamento_tipo_id: {
        required
      },
      carga_horaria_teorica: {
        required
      },
      carga_horaria_pratica: {
        required
      },
      conteudo_programatico: {
        required
      }
    }
  },
  data () {
    return {
      normas: [],
      processos: [
        {id: 0, nome: 'Selecione uma norma'}
      ],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: ['heading', 'bold', 'italic', 'bulletedList', 'numberedList', 'indent', 'outdent']
        }
      },
      tiposTreinamento: [],
      treinamento: {
        objetivo: '',
        treinamento_tipo_id: null,
        nome_atividade: null,
        carga_horaria: null,
        conteudo_programatico: '',
        norma_id: null,
        processo_id: null
      }
    }
  },
  created () {
		if (this.$route.query.id) {
			this.buscarTreinamentoModelo(this.$route.query.id)
    }
    this.fetch()
	},
  methods: {
    async fetch () {
      try {
        this.$store.commit("setSplashScreen", true)
        let response = await treinamentoTiposRepository.listarTiposTreinamento()
        if(response.data['success']) {
          this.tiposTreinamento = response.data['data']
        }
        let responseNorma = await normasRepository.listarNormas()
        if(responseNorma.data['success']) {
          this.normas = responseNorma.data['data']
        }
        this.$store.commit("setSplashScreen", false)
      } catch (error) {
        console.error(error)
        this.$store.commit("setSplashScreen", false)
      } finally {
        this.$store.commit("setSplashScreen", false)
      }
      
    },
    buscarProcessos () {
      this.$store.commit("setSplashScreen", true)
      let normaId = this.treinamento.norma_id
      normasRepository.listarProcessosPorNormaId(normaId).then(response => {
        this.$store.commit("setSplashScreen", false)
				if(response.data['success']) {
          this.processos = response.data['data']
        }
			}).catch(error => {
        console.error(error)
        this.$store.commit("setSplashScreen", false)
      })
    },
    buscarTreinamentoModelo (idTreinamento) {
      this.$store.commit("setSplashScreen", true)
      treinamentoModelosRepository.buscaModeloTreinamento(idTreinamento).then(response => {
        this.$store.commit("setSplashScreen", false)
				if(response.data['success']) {
          this.treinamento = response.data['data']
          this.buscarProcessos()
        }
			}).catch(error => {
        console.log(error)
        this.$store.commit("setSplashScreen", false)
      })
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'FuncionariosCargos' })
    },
    objetivoCaracteres() {
       let tamanho = this.treinamento.objetivo.length
       // se passar do valor limite proibe digitar
       if (tamanho === 1000) {
         this.treinamento.objetivo.slice(0, -1)
       }
       let restante = 1000 - tamanho
       return `${restante} caracteres restantes`
    },
    salvar () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$swal({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha os campos obrigatórios!',
        })
      } else {
        if(this.treinamento.norma_id != null && this.treinamento.processo_id != null) {
          let norma = this.normas.find(x => x.id == this.treinamento.norma_id)
          let processo = this.processos.find(x => x.id == this.treinamento.processo_id)
          this.treinamento.nome_atividade = `${norma.nome} - ${processo.nome}`
        }
        
        if (this.treinamento.id) {
					treinamentoModelosRepository.atualizarModeloTreinamento(this.treinamento.id, this.treinamento).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch( error => {
						console.log(error)
						this.$swal({
							icon: 'error',
							text: 'Não foi possível realizar o cadastro!'
						})
					})
				} else {
					treinamentoModelosRepository.salvarModeloTreinamento(this.treinamento).then(response => {
            if (response.data['success']) {
              this.$swal({
                icon: 'success',
                text: 'Cadastro realizado com sucesso!'
              }).then( () => {
                this.goBack()
              })
            }
          }).catch(error => {
            console.log(error)
            this.$swal({
              icon: 'error',
              title: 'Erro no cadastro!',
              text: error.message
            })
          })
				}
      }
    }
  }
}
</script>

<style>
.ck-content { height:300px; }
</style>